import React from 'react';
import config from '../../config';
import avatar from '../assets/images/avatar.jpg'

export default function Header() {
  return (
    <header id="header">
      <p> <img src={avatar} alt="avatar" /></p>
      <p> <h1>{config.authorName}</h1></p>
      {config.authorHeading.map(heading => (
        <p key={heading}>{heading}</p>
      ))}
      <nav>
        <ul>
          {config.authorSocialLinks.map(social => {
            const { icon, name, url } = social;
            return (
              <li key={name}>
                <a href={url} className={`icon ${icon}`}>
                  <span className="label">{name}</span>
                </a>
              </li>
            );
          })}
        </ul>
      </nav>
    </header>
  );
}
